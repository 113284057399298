import React from 'react'
import Navbar from '../../components/navbar'
import FooterAll from '../../components/footer2'
import PrintMedia from './print-media';
import DigitalMedia from './digital-media';
import Videos from './video';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function InTheMedia() {
    const canonical = window.location.href;


    return (
        <div>
            <Helmet>
            <title>Saya Homes | In the Media</title>
            <meta name="description" content="Know more about Saya Homes, about our newly launched residential and commercial projects, community initiatives, and achievements." />
            <meta name="keywords" content="in the media, print media, digital media" />
            <link rel="canonical" href={canonical}/>
            </Helmet>
            <Navbar />
            <div className="emptyBox"></div>
            <div className="pageTitle py-5 bg-orange w-100">
                <div className="container-lg">
                    <h1 className="display-4 mb-0">In the Media</h1>
                </div>
            </div>
            <div className="breadcrumbContainer">
                <div className="container-lg">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">In the Media</li>
                    </ol>
                </div>
            </div>
            <PrintMedia />
            <DigitalMedia />
            <Videos />
            <FooterAll />
        </div>
    )
}

export default InTheMedia