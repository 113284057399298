import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/navbar';
import FooterAll from '../../components/footer2';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import $ from "jquery";
import "jquery.magnify"; // Import the Magnify plugin

function EventDetail() {
    const [events, setEvents] = useState([]);
    const { slugURL} = useParams();
    const location = useLocation();
    const { meta_title, meta_keyword, meta_description } = location.state || {}; // Access the passed data

    const canonical = window.location.href;
    const navigate = useNavigate();

    useEffect(() => {
            const fetchEvents = async (slugURL) => {
                try {
                const response = await axios.get(`https://ecis.in/apis/sayahomes/events/getBySlug/${slugURL}`);
                setEvents(response.data.data);
            } catch (error) {
                console.error('Error fetching events:', error);
                navigate('/404-not-found')
            }
        };

        fetchEvents(slugURL);
    }, [slugURL]); 

    useEffect(() => {
        // Initialize Magnify on the target element
        $("#event-image").magnify({
            speed: 200,
            afterLoad: function () {
              // Add a class to the magnified image container for custom styling
              $(".magnify-lens").addClass("custom-magnify");
            },
          });
      }, []);

    

    const imageStyle = {
        height: '200px',
        width: '100%',
        objectFit: 'cover',
        transition: 'transform 0.3s ease-in-out',
    };

    const imageHoverStyle = {
        transform: 'scale(1.1)',
    };

    return (
        <div>
            <Navbar />
            {events && (
                <Helmet>
                    <title>{meta_title || 'Saya Homes - Delhi-NCR Leading Real Estate Developer'}</title>
                    <meta name="description" content={meta_description || 'Saya Homes is a top real estate developer in Delhi-NCR, specializing in high-quality residential and commercial projects. Find your dream home with us today!'} />
                    <meta name="keywords" content={meta_keyword || 'saya homes, property in noida, best property in greater noida, property for sale in noida, luxury residential property in greater noida, residential property in ghaziabad, residential projects in noida, apartments in greater noida, commercial projects in greater noida west, housing projects in ghaziabad, construction company in noida'} />
                    <link rel="canonical" href={canonical}/>
                </Helmet>
            )}
            <div className="emptyBox"></div>
            <div className="pageTitle py-5 bg-orange w-100">
                <div className="container-lg">
                    <h1 className="display-4 mb-0">Saya Events</h1>
                </div>
            </div>
            <div className="breadcrumbContainer">
                <div className="container-lg">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                        <li className="breadcrumb-item">
                            <Link to="/events">Events</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {slugURL.toString().trim().replace(/-/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                        </li>
                    </ol>
                </div>
            </div>
            <div className="w-100 padding position-relative">
                <div className="container-lg">
                    <div className="inside-blogsArchive">
                        <div className="row gap-row">
                            {events.slice(0, 25).map((event) => (
                                <div key={event.id} className="col-6 col-md-3 mb-4">
                                    <div
                                        className="img-fluid mt-0 position-relative"
                                        style={{ overflow: 'hidden' }} // Ensures the image doesn't overflow its container
                                    >
                                        <a href={`https://www.sayahomes.com/uploads/events/${event.ev_image}`} className="inner" data-magnify="gal" data-group="exhib" data-caption={event.title}>
                                        <img
                                        id='event-image'
                                            src={`https://www.sayahomes.com/uploads/events/${event.ev_image}`}
                                            alt={event.ev_title}
                                            style={imageStyle}
                                            className="zoom-image"
                                            onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.1)'}
                                            onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
                                        /></a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <FooterAll />
        </div>
    );
}

export default EventDetail;
