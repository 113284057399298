import React from 'react'
import Navbar from '../../components/navbar'
import FooterAll from '../../components/footer2'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
function Commercial() {
    const canonical = window.location.href;
    return (
        <div>
            <Navbar />
            <Helmet>

            <title>Saya Homes | Top Commercial Property in Greater Noida West</title>	
            <meta name="description" content="Explore premium commercial projects in Greater Noida with Saya Homes. Offering commercial properties office spaces, retail shops, and business suites on Noida expressway." />	
            <meta name="keywords" content="saya homes, commercial property in greater noida, commercial property in greater noida west, commercial property on noida expressway, top commercial property in greater noida, best commercial property in greater noida, commercial projects in greater noida, commercial projects in greater noida west" />
            <link rel="canonical" href={canonical}/>
                </Helmet>
            <div>
                <div className="emptyBox"></div>
                <div className="pageTitle py-5 bg-orange w-100">
                    <div className="container-lg">
                        <h1 className="display-4 mb-0">Commercial</h1>
                    </div>
                </div>
                <div className="breadcrumbContainer">
                    <div className="container-lg">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Commercial</li>
                        </ol>
                    </div>
                </div>
                <div className="w-100 padding position-relative">
                    <div className="container-lg">
                        <div className="row gap-row">
                            <div className="col-md-4 col-sm-6 hm-featured">
                                <Link to='/saya-southX' className="inner">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/saya-south-x-th.webp" alt="Saya South X" title='Saya South X'/><i className="fa-solid fa-arrow-up-right-from-square"></i></div>
                                    <div className="text">
                                        <span className="line"></span>
                                        <div className="projectBoxName">
                                            <h2>Saya South X</h2>
                                            {/* <p className="mb-0">Mix Used retail destination, Ek Murti Chowk, Greater Noida West (Nearing Possession)</p> */}
                                            <ul className="list-inline d-flex">
                                                <li>Commercial</li>
                                                <li>Gr. Noida (W)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-sm-6 hm-featured">
                                <Link to='/saya-biztop' className="inner">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/biztop-th.webp" alt="Biztop" title='Biztop' /><i className="fa-solid fa-arrow-up-right-from-square"></i></div>
                                    <div className="text">
                                        <span className="line"></span>
                                        <div className="projectBoxName">
                                            <h2>Biztop</h2>
                                            {/* <p className="mb-0">Premium & fully furnished business suites that cater to the elite corporate className at Ek Murti Chowk, Gr. Noida (W). </p> */}
                                            <ul className="list-inline d-flex">
                                                <li>Commercial</li>
                                                <li>Gr. Noida (W)</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 col-sm-6 hm-featured">
                                <Link to='/saya-piazza' className="inner">
                                    <div className="img-fluid"><img src="https://sayahomes.com/assets/images/saya-piazza.webp" alt="Saya Piazza" title='Saya Piazza'/><i className="fa-solid fa-arrow-up-right-from-square"></i></div>
                                    <div className="text">
                                        <span className="line"></span>
                                        <div className="projectBoxName">
                                            <h2>Saya Piazza</h2>
                                            {/* <p className="mb-0">Blockbuster Shopping destination, Sector 131, Jaypee Wish Town Noida. (Nearing Possession)</p> */}
                                            <ul className="list-inline d-flex">
                                                <li>Commercial</li>
                                                <li>Noida Expressway</li>
                                            </ul>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="logo-icon"><img src="https://sayahomes.com/assets/images/logo-icon.svg" alt="Saya Homes" title='Saya Homes'/></div>
            </div>
            <FooterAll />
        </div>
    )
}

export default Commercial