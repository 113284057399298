import React from 'react';
import Navbar from '../components/navbar';
import FooterAll from '../components/footer2';

function NotFound() {
  const styles = {
    body: {
      margin: 0,
      padding: 0,
      textAlign: 'center',
      fontFamily: 'sans-serif',
      backgroundColor: '#E7FFFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    section: {
      padding: '10rem 2rem',
    },
    error: {
      fontSize: '150px',
      color: 'rgb(34 53 108)',
      textShadow: `
       rgb(0, 89, 62) 1px 1px 1px, rgb(0, 89, 62) 2px 2px 1px, rgb(34 53 108) 3px 3px 1px, rgb(197 48 59) 4px 4px 1px, rgb(34 53 108) 5px 5px 1px, rgb(0, 89, 62) 6px 6px 1px, rgb(197 48 59) 7px 7px 1px, rgb(245 135 61) 8px 8px 1px, rgba(0, 0, 0, 0.2) 25px 25px 8px
      `,
    },
    page: {
      margin: '2rem 0',
      fontSize: '20px',
      fontWeight: 600,
      color: '#444',
    },
    backHome: {
      display: 'inline-block',
      border: '2px solid #222',
      color: '#fff',
      textTransform: 'uppercase',
      fontWeight: 600,
      padding: '0.75rem 1rem 0.6rem',
      transition: 'all 0.2s linear',
      boxShadow: '0 15px 15px -11px rgba(0,0,0, 0.4)',
      background: '#222',
      borderRadius: '6px',
      textDecoration: 'none',
    },
    backHomeHover: {
      background: '#222',
      color: '#ddd',
    },
  };

  return (
    <>
    <Navbar/>
    <div className='emptyBox'></div>
    <div style={styles.body}>
      <div style={styles.section}>
        <h1 style={styles.error}>404</h1>
        <div style={styles.page}>Ooops!!! The page you are looking for is not found</div>
        <a
          style={styles.backHome}
          href="/"
          onMouseOver={(e) => (e.target.style.color = styles.backHomeHover.color)}
          onMouseOut={(e) => (e.target.style.color = styles.backHome.color)}
        >
          Back to home
        </a>
      </div>
    </div>
    <FooterAll/>
    </>
  );
}

export default NotFound;
