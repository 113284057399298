import React from 'react';
import Navbar from '../../components/navbar';
import FooterAll from '../../components/footer2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
function Residentials() {
    const canonical = window.location.href;
    return (
        <>
        <Navbar />
        <Helmet>
        <title>Saya Homes | Top Residential Property in Greater Noida West, Ghaziabad</title>	
        <meta name="description" content="Saya Homes has delivered projects like Saya Gold AvenueDiscover top residenial projects in Greater Noida west with Saya Homes. Explore luxurious residential properties in Indirapuram, Ghaziabad with modern amenities." />	
        <meta name="keywords" content="saya homes, residential property in greater noida west, top residential property in greater noida west, residential property in ghaziabad, top residential property in ghaziabad, best residential property in indirapuram, residential projects in indirapuram, residential projects in ghaziabad" />
        <link rel="canonical" href={canonical}/>
            </Helmet>
            <div className="emptyBox"></div>
            <div className="pageTitle py-5 bg-orange w-100">
                <div className="container-lg">
                    <h1 className="display-4 mb-0">Residential</h1>
                </div>
            </div>
            <div className="breadcrumbContainer">
                <div className="container-lg">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Residential</li>
                    </ol>
                </div>
            </div>
            <div className="w-100 padding position-relative">
                <div className="container-lg">
                    <div className="row gap-row">
                        <div className="col-md-4 col-sm-6 hm-featured">
                            <Link to='/saya-gold-avenue' className="inner">
                                <div className="img-fluid"><img src="https://sayahomes.com/assets/images/saya-gold-th (1).webp" alt="Saya Gold Avenue" title='Saya Gold Avenue'/><i className="fa-solid fa-arrow-up-right-from-square"></i></div>
                                <div className="text">
                                    <span className="line"></span>
                                    <div className="projectBoxName">
                                        <h2>Saya Gold Avenue</h2>
                                        {/* <p className="mb-0">2/3/4 BHK luxury homes designed for the elite className, reflecting exclusivity and sophistication.</p> */}
                                        <ul className="list-inline d-flex">
                                            <li>Residential</li>
                                            <li>Ghaziabad</li>
                                        </ul>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4 col-sm-6 hm-featured">
                            <Link to='/saya-zion' className="inner">
                                <div className="img-fluid"><img src="https://sayahomes.com/assets/images/saya-zion.webp" alt="Saya Zion" /><i className="fa-solid fa-arrow-up-right-from-square"></i></div>
                                <div className="text">
                                    <span className="line"></span>
                                    <div className="projectBoxName">
                                        <h2>Saya Zion</h2>
                                        {/* <p className="mb-0">Zion perfectly captures the grandeur of its expansive green surroundings in Greater Noida West.</p> */}
                                        <ul className="list-inline d-flex">
                                            <li>Residential</li>
                                            <li>Gr. Noida (W)</li>
                                        </ul>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4 col-sm-6 hm-featured">
                            <Link to='/saya-zenith' className="inner">
                                <div className="img-fluid"><img src="https://sayahomes.com/assets/images/saya-zenith.webp" alt="Saya Zenith" /><i className="fa-solid fa-arrow-up-right-from-square"></i></div>
                                <div className="text">
                                    <span className="line"></span>
                                    <div className="projectBoxName">
                                        <h2>Saya Zenith</h2>
                                        {/* <p className="mb-0">Saya brings to you Zenith, apartments artfully curated for the discerning you.</p> */}
                                        <ul className="list-inline d-flex">
                                            <li>Residential</li>
                                            <li>Ghaziabad</li>
                                        </ul>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-md-4 col-sm-6 hm-featured">
                            <Link to='/saya-desire-residency' className="inner">
                                <div className="img-fluid"><img src="https://sayahomes.com/assets/images/saya-desire-residency.webp" alt="Saya Desire Residency" /><i className="fa-solid fa-arrow-up-right-from-square"></i></div>
                                <div className="text">
                                    <span className="line"></span>
                                    <div className="projectBoxName">
                                        <h2>Saya Desire Residency</h2>
                                        {/* <p className="mb-0">At the heart of Indirapuram, Saya brings to you Desire Residency.</p> */}
                                        <ul className="list-inline d-flex">
                                            <li>Residential</li>
                                            <li>Ghaziabad</li>
                                        </ul>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="logo-icon"><img src="./images/logo-icon.svg" alt="Saya Homes" title='Saya Homes'/></div>

            <FooterAll />
        </>
    );
}

export default Residentials;
