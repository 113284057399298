import React from 'react'

function AboutOverview() {
  return (
    <div>
         <div className="w-100 padding position-relative overflow-hidden">
         <a id="overview" name="overview"></a>
                    <div className="container-lg">
                        <div className="heading">
                            <h2 className="text-serif">Saya Group, established 25 years ago, is a trailblazer in the real estate industry with a strong presence in the Delhi NCR region. Known for pioneering luxury residential and commercial developments, Saya Group began with the development of low-rise floors and luxury villas, and soon expanded into high-rise luxury homes and commercial projects.</h2>
                            <hr />
                                <p>Saya Group a portfolio of over 5.37 million sq. ft. of luxurious residential spaces and innovative commercial projects. This includes 2.37 million sq. ft. of high-street retail malls in Noida and Greater Noida West. Our diverse range of developments underscores our commitment to meeting the evolving needs of our customers and stakeholders.</p>
                                <p className="mb-0">At the core of Saya Group is our unwavering dedication to quality, prime locations, fully paid-up lands, and timely delivery. Driven by our committed employees and valued customers, we continually strive for excellence. Our investment in innovation, empowerment, and optimism propels us forward, ensuring a future that honors our legacy of excellence and customer satisfaction.</p>
                        </div>

                        <div className="saya-stats">
                            <div className="stats-logo"><div><img src="https://sayahomes.com/assets/images/logo-icon.svg" alt='Saya Homes' Saya Homes/></div></div>
                            <div className="row gap-row">
                                <div className="col-lg-3 col-sm-6 saya-stats-box">
                                    <div className="inner">
                                        <h3 className="text-serif">2 Decades of</h3>
                                        <p className="mb-0">Delivering Excellence</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 saya-stats-box">
                                    <div className="inner">
                                        <h3 className="text-serif">4,000+</h3>
                                        <p className="mb-0">Happy Families</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 saya-stats-box">
                                    <div className="inner">
                                        <h3 className="text-serif">Over 5.37</h3>
                                        <p className="mb-0">Million Sq.Ft. Delivered (Residential)</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6 saya-stats-box">
                                    <div className="inner">
                                        <h3 className="text-serif">Over 2.37</h3>
                                        <p className="mb-0">Million Sq.Ft. Delivered (Commercial)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 feature-imgs-wrapper">
                                <div className="position-relative overflow-hidden">
                                    <div className="row">
                                        <div className="col-6 feature-img-box"><img src="https://sayahomes.com/assets/images/about-img1.webp" alt="Saya Homes-about" /></div>
                                        <div className="col-6 feature-img-box"><img src="https://sayahomes.com/assets/images/about-img2.webp" alt="Saya Homes-about" /></div>
                                    </div>
                                    <div className="gradient-overlay"></div>
                                </div>
                            </div>
                            <div className="col-6 feature-imgs-wrapper">
                                <div className="position-relative overflow-hidden">
                                    <div className="row">
                                        <div className="col-6 feature-img-box"><img src="https://sayahomes.com/assets/images/about-img33.webp" alt="Saya Homes-about" /></div>
                                        <div className="col-6 feature-img-box"><img src="https://sayahomes.com/assets/images/about-img4.webp" alt="Saya Homes-about" /></div>
                                    </div>
                                    <div className="gradient-overlay reverse d-none d-lg-block"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
  )
}

export default AboutOverview