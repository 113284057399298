import { Link, useNavigate, useParams } from "react-router-dom";
import FooterAll from "../../components/footer2";
import Navbar from "../../components/navbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

export default function BlogsDetail() {
  const { slugurl } = useParams();
  const [previousBlog, setPreviousBlog] = useState([]);
  const [nextBlog, setNextBlog] = useState([]);
  const [blog, setBlog] = useState(null);

  const canonical = window.location.href;
  const navigate = useNavigate();

  useEffect(() => {
    fetchBlog(slugurl);
  }, [slugurl]);

  const fetchBlog = async (slugurl) => {
    try {
      const result = await axios.get(`https://ecis.in/apis/sayahomes/blog/getBySlug/${slugurl}`);
      setBlog(result.data.data); 
      const currentBlogID = result.data.data.id
      fetchPreviousAndNextBlog(currentBlogID)
    } catch (error) {
      navigate('/404-not-found')

    }
  };

  const fetchPreviousAndNextBlog = async (currentBlogID) => {
    try{
      const result = await axios.get('https://ecis.in/apis/sayahomes/blog/get');
      const previous = result.data.filter((adv) => adv.id === currentBlogID-1)
      const next = result.data.filter((adv) => adv.id === currentBlogID+1)
      setPreviousBlog(previous[0] || '')
      setNextBlog(next[0] || '')
    }
    catch(error){
    }
  }

  if (!blog) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Navbar />
      {blog && (
                <Helmet>
                    <title>{blog.meta_title || 'Saya Homes - Delhi-NCR Leading Real Estate Developer'}</title>
                    <meta name="description" content={blog.meta_desc || 'Saya Homes is a top real estate developer in Delhi-NCR, specializing in high-quality residential and commercial projects. Find your dream home with us today!'} />
                    <meta name="keywords" content={blog.meta_key || 'saya homes, property in noida, best property in greater noida, property for sale in noida, luxury residential property in greater noida, residential property in ghaziabad, residential projects in noida, apartments in greater noida, commercial projects in greater noida west, housing projects in ghaziabad, construction company in noida'} />
                    <link rel="canonical" href={canonical}/>
                </Helmet>
            )}
      
      <div className="w-100 padding position-relative">
      <div className="breadcrumbContainer">
        <div className="container-lg">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/blogs">Blogs</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {blog.heading} {/* Assuming the blog object has a title */}
            </li>
          </ol>
        </div>
      </div>
      <div className="w-30 padding position-relative"></div>
        <div className="container-lg">
          <div className="row gap-row">
            <div className="col-lg-8 main-blog-box">
              <div className="inner">
                <div className="heading">
                  <h6>Posted on {new Date(blog.date).toLocaleDateString()} by {blog.byteam}</h6> {/* Adjust according to your API response */}
                  <h2 className="h1 text-serif mb-0">{blog.heading}</h2> {/* Assuming the blog object has a title */}
                </div>
                <figure className="h-100">
                  <img
                    src={`https://www.sayahomes.com/uploads/blog-images/${blog.image}`}
                    alt={blog.heading} // Alt text should be descriptive
                    className="h-100 object-cover"
                  />
                </figure>
                <article className="main-blog-body">
                  <div dangerouslySetInnerHTML={{ __html: blog.content }} /> {/* Assuming blog.content contains HTML */}
                </article>
                <div className="form-row form-gap-row mt-4">
                  <div className="col-sm-6 blogsPage-control">
                    {previousBlog.heading && (
                      <a href={previousBlog.slugurl} className="">
                        <i className="fa fa-long-arrow-left"></i>
                        <p className="mb-0"><span className="d-block">Previous</span>{previousBlog.heading.slice(0,30)}...</p> 
                      </a>
                    )}
                  </div>
                  <div className="col-sm-6 blogsPage-control text-right">
                    {nextBlog.heading && (
                      <a href={nextBlog.slugurl} className="text-right">
                        <p className="mb-0"><span className="d-block">Next</span>{nextBlog.heading.slice(0,30)}...</p> 
                        <i className="fa fa-long-arrow-right"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterAll />
    </>
  );
}
