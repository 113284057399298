import axios from "axios";
import { useEffect, useState } from "react";

export default function SocialMedia() {
    useEffect(() => {
        const interval = setInterval(() => {
            const modal = document.querySelector('[data-target="#exampleModal"]');
            if (modal) {
                modal.click(); 
            }
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollPosition = window.scrollY;
    //         const documentHeight = document.documentElement.scrollHeight - window.innerHeight;
    //         if (scrollPosition >= documentHeight * 0.2) {
    //             const modal = document.querySelector('[data-target="#exampleModal"]');
    //             if (modal) {
    //                 modal.click(); 
    //                 window.removeEventListener('scroll', handleScroll);
    //             }
    //         }
    //     };
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({
        modalType: '',
        pageName: '',
        projectName: '',
        brochure: '',
        brochureUrl: ''
    });

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        message: ''
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleCloseModal = () => setShowModal(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newcreated = new Date().toISOString().slice(0, 19).replace('T', ' ');

        const dataToSend = {
            ...formData,
            projects: modalData.projectName,
            source: modalData.modalType,
            medium: modalData.pageName,
            campaign: modalData.brochure,
            created: newcreated
        };

        try {
            const response = await axios.post('https://ecis.in/apis/sayahomes/projectQuery/upload', dataToSend);
            console.log('Form submitted successfully:', response.data);
            setSuccessMessage('Your enquiry has been submitted successfully.');
            setFormData({ name: '', mobile: '', email: '', message: '' });
            handleCloseModal();
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrorMessage('There was an error submitting your enquiry. Please try again.');
        }
    };
    return (
        <>
        <div className="enquiryBtn">
            <a href="#exampleModal" data-toggle="modal"><i className="fa fa-envelope"></i></a>
        </div>
        
        <div className="enquiryBtn enquiryBtnw">
        <a href="https://api.whatsapp.com/send?phone=+918860763281&text=Hi I am interested in Saya Homes, Please share the details." target="_blank"><img src="/assets/images/whatsapp.webp"/></a>
        </div>
        <div className="enquiryBtn"><a href="javascript:;" data-toggle="modal" data-target="#exampleModal"><i className="fa fa-envelope"></i></a></div>

        <div className="modal fade show" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-modal="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <button type="button" className="close d-flex align-items-center justify-content-center" data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}>
                                <span aria-hidden="true" className="ion-ios-close">×</span>
                            </button>
                            <div className="row no-gutters">
                                <div className="col-lg-12 col-sm-8">
                                    <div className="modal-body">
                                        <h5 className="mb-3">If you have any query, fill the form.</h5>
                                        {successMessage && <div className="alert alert-success">{successMessage}</div>}
                                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                                        <form className="modal-form" id="modalform" onSubmit={handleSubmit}>
                                            <div className="form-row">
                                                <div className="col-md-12 form-group">
                                                    {/* <label className="customCursor" htmlFor="mname"></label> */}
                                                    <input type="text" className="form-control" id="mname" name="name" value={formData.name} onChange={handleChange} placeholder="Name *" required />
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    {/* <label className="customCursor" htmlFor="mmobile"></label> */}
                                                    <input type="tel" className="form-control" name="mobile" id="mmobile" value={formData.mobile} onChange={handleChange} placeholder="Mobile *" required />
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    {/* <label className="customCursor" htmlFor="memail"</label> */}
                                                    <input type="email" className="form-control" name="email" id="memail" value={formData.email} onChange={handleChange} placeholder="Email *" required />
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    {/* <label className="customCursor" htmlFor="mquery"></label> */}
                                                    <textarea className="form-control" name="message" id="mmessage" data-gramm="false" value={formData.message} onChange={handleChange} placeholder="Query"></textarea>
                                                </div>
                                                <div className="col-md-12 form-group">
                                                    <p className="mb-0"><small><input type="checkbox" required /> I authorise Saya Group and its representatives to Call, SMS, Email or WhatsApp me about its projects and offers. This consent overrides any registration for DNC / NDNC.</small></p>
                                                </div>
                                                <div className="col-md-12 formFooter readmore mt-1">
                                                    <input type="hidden" name="mmodal" value="active" />
                                                    {/* <input type="hidden" name="mpagename" id="mpagename" value={modalData.pageName} />
                                                    <input type="hidden" name="projectName" id="projectName" value={modalData.projectName} />
                                                    <input type="hidden" name="mbrochure" id="mbrochure" value={modalData.brochure} />
                                                    <input type="hidden" name="brouchureurl" id="brouchureurl" value={modalData.brochureUrl} /> */}
                                                    <button type="submit" className="button solid" id="modalsubmit"><span className="text">Submit</span></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}
